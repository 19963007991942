<template>
  <div>
    <div class="seleteBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="SU模型" name="su" lazy></el-tab-pane>
        <el-tab-pane label="3D模型" name="max" lazy></el-tab-pane>
        <el-tab-pane label="PS素材" name="ps" lazy> </el-tab-pane>
        <el-tab-pane label="方案文本" name="schemeText" lazy></el-tab-pane>
        <el-tab-pane label="资料库" name="material" lazy></el-tab-pane>
        <el-tab-pane label="课程" name="course" lazy> </el-tab-pane>
        <el-tab-pane label="图库" name="photo" lazy> </el-tab-pane>
        <el-tab-pane label="贴图" name="chartlet" lazy> </el-tab-pane>
      </el-tabs>
      <el-input
        class="searchInput"
        placeholder="请输入搜索内容"
        suffix-icon="el-icon-search"
        v-model="workName"
        @blur="changeWorkName"
      >
      </el-input>
      <!-- <el-date-picker
        v-model="time"
        type="daterange"
        unlink-panels
        range-separator="————"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="changeTime"
      >
      </el-date-picker> -->
    </div>
    <div class="selectStatusList">
      <div
        class="selectStatusItem"
        :class="{ active: uploadType == 1 }"
        @click="changeUpdateType(1)"
      >
        已通过
      </div>
      <div
        class="selectStatusItem"
        :class="{ active: uploadType == 2 }"
        @click="changeUpdateType(2)"
      >
        待审核
      </div>
      <div
        class="selectStatusItem"
        :class="{ active: uploadType == 3 }"
        @click="changeUpdateType(3)"
      >
        未通过
      </div>
      <div
        class="selectStatusItem"
        :class="{ active: uploadType == 4 }"
        @click="changeUpdateType(4)"
      >
        已下架
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MyUploadTab",
  data() {
    return {
      activeName: "su",
      uploadType: 1,
      workName: "",
      time: "",
    };
  },
  created() {
    if (this.$route.query.uploadType) {
      this.uploadType = this.$route.query.uploadType;
      this.changeUpdateType(this.uploadType);
    }
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
    this.$emit("changeCate", this.activeName);
  },
  methods: {
    changeWorkName() {
      this.$emit("changeWorkName", this.workName);
    },
    handleClick() {
      this.$emit("changeCate", this.activeName);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.activeName = this.activeName; //改变参数
      //如果切换到图库自动切换分类
      // if (this.activeName == "photo") {
      //   let v = 0;
      //   if (this.uploadType > 2) {
      //     v = 1;
      //   } else {
      //     v = this.uploadType;
      //   }
      //   this.uploadType = v;
      //   query.uploadType = v;
      // }
      this.$router.push({ path: this.$route.path, query });
    },
    changeUpdateType(val) {
			// if( this.uploadType == val ) return
      this.uploadType = val;
      let v;
      if (val == 1) {
        v = 2;
      } else if (val == 2) {
        v = 1;
      } else if (val == 3) {
        v = -1;
      } else if (val == 4) {
        v = 5;
      }
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.uploadType = val; //改变参数
      this.$router.push({ path: this.$route.path, query });
      this.$emit("changeUploadType", v);
    },
  },
};
</script>
<style scoped>
.seleteBox >>> .el-input__inner {
  width: 2.2rem;
  height: 0.32rem;
  border-radius: 4px;
}
.seleteBox >>> .el-input {
  width: 2.2rem;
  border-radius: 4px;
}

.selectStatusItem.active {
  background: #d9d9d9;
}
.selectStatusItem:hover {
  background: #d9d9d9;
}
.selectStatusItem {
  width: 0.58rem;
  height: 0.3rem;
  margin-right: 0.15rem;
  border-radius: 0.04rem;
  color: #666666;
  font-size: 00.14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.selectStatusList {
  display: flex;
  align-items: center;
  margin-top: 00.3rem;
}
.seleteBox >>> .el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
  font-size: 00.14rem;
}
.seleteBox >>> .el-date-editor .el-range-separator {
  line-height: 25px;
  width: 10%;
}
.seleteBox >>> .el-date-editor .el-range__icon {
  line-height: 25px;
}
.seleteBox >>> .el-range-editor.el-input__inner {
  height: 30px;
  width: 250px;
}
.seleteBox >>> .el-tabs__header {
  margin: 0;
}
.seleteBox >>> .el-tabs__item {
  height: 00.4rem;
  line-height: 00.4rem;
}
.seleteBox >>> .el-tabs__active-bar {
  background-color: #f46600;
  bottom: 1px;
}
.seleteBox >>> .el-tabs__item.is-active {
  color: #f46600;
}
.seleteBox >>> .el-tabs__item:hover {
  color: #f46600;
  cursor: pointer;
}
.seleteBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 0;
  position: relative;
  line-height: 0.6rem;
  height: 0.6rem;
  margin-top: 0.35rem;
}
.seleteBox::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #e4e7ed;
  z-index: 1;
}
</style>
<style>
.el-date-editor .el-range__close-icon {
  line-height: 25px;
}
</style>