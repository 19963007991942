<!--
 * @FilePath: \zsw_new_edition\src\views\upload\myUpload.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-02 11:24:43
-->
<template>
  <div class="myuploadBox">
    <MyUploadStatistics></MyUploadStatistics>
    <MyUploadTab
      @changeCate="changeCate"
      @changeUploadType="changeUploadType"
      @initData="initData"
      @changeWorkName="changeWorkName"
    ></MyUploadTab>
    <MyUploadList
      ref="MyUploadList"
      :workList="workListObj"
      @setPage="setPage"
      :cateId="getListParams.cateId"
      @initData="initData"
      @nextPage="nextPage"
    ></MyUploadList>
  </div>
</template>

<script>
import MyUploadStatistics from "./componts/MyUploadStatistics.vue";
import MyUploadTab from "./componts/MyUploadTab.vue";
import MyUploadList from "./componts/MyUploadList.vue";
import { mapGetters } from "vuex";
import { getToken } from "../../utils/auth";
export default {
  name: "MyUpload",
  data() {
    return {
      activeName: "",
      loading: true,
      list: [],
      getListParams: {
        page: 1, // 分页
        token: getToken(),
        cateId: "", //默认su
        per_page: 6,
        checkState: 2, //默认展示已通过
        offState: "",
        startTime: "",
        endTime: "",
        workName:""
      },
    };
  },
  components: {
    MyUploadStatistics,
    MyUploadTab,
    MyUploadList,
  },
  created() {},
  methods: {
    clear() {
      if (this.getListParams.cateId == 2381) this.$refs.MyUploadList&&this.$refs.MyUploadList.clear();
      if (this.getListParams.cateId == 2205) this.$refs.MyUploadList&&this.$refs.MyUploadList.clear();
    },
    //初始化
    initData(cateId = "") {
      let v = 2;
       if (this.$route.query.uploadType == 1) {
        v = 2;
      } else if (this.$route.query.uploadType == 2) {
        v = 1;
      } else if (this.$route.query.uploadType == 3) {
        v = -1;
      } else if (this.$route.query.uploadType == 4) {
        v = 5;
      }
			console.log(123);
      if (this.getListParams.cateId == 2205) this.$refs.MyUploadList.clear();
			setTimeout(()=> {
				this.getListParams = {
					page: 1, // 分页
					token: getToken(),
					cateId: cateId, //默认su
					per_page: 6,
					checkState: v, //默认展示已通过
					offState: "",
					startTime: "",
					endTime: "",
					workName:""
				};
			}, 0)
    },
    changeWorkName(workName) {
      this.getListParams.page = 1;
      this.getListParams.workName = workName;
      this.clear();
    },
    changeTime(data) {
      this.getListParams.page = 1;
      this.getListParams.startTime = data[0];
      this.getListParams.endTime = data[1];
      this.clear();
    },
    //选择菜单
    changeCate(data) {
      this.getListParams.page = 1;
      if (data == "su") {
        this.getListParams.cateId = 561;
        this.getListParams.per_page = 6;
      } else if (data == "max") {
        this.getListParams.cateId = 1393;
        this.getListParams.per_page = 6;
      }else if (data == "schemeText") {
        this.getListParams.cateId = 1394;
        this.getListParams.per_page = 6;
      }else if (data == "material") {
        this.getListParams.cateId = 7;
        this.getListParams.per_page = 6;
      } else if (data == "course") {
        this.getListParams.cateId = 6;
        this.getListParams.per_page = 6;
      } else if (data == "photo") {
        // this.getListParams.cateId = 1068;
        this.getListParams.cateId = 2381;
        this.getListParams.per_page = 15;
        // this.getListParams.offState = 2;
      } else if (data == "chartlet") {
        this.getListParams.cateId = 2205;
        this.getListParams.per_page = 15;
      }else if (data == "ps") {
        this.getListParams.cateId = 1196;
        this.getListParams.per_page = 6;
      }
      this.clear();
    },
    //设置分页
    setPage(val) {
      this.getListParams.page = val;
    },
    //滚动翻页
    nextPage() {
      this.getListParams.page += 1;
    },
    //选择状态
    changeUploadType(data) {
      this.getListParams.page = 1;
      //如果选中下架
      if (data == 5) {
        this.getListParams.checkState = "";
        this.getListParams.offState = 2;
      } else {
        this.getListParams.checkState = data;
        this.getListParams.offState = "";
      }
      this.clear();
    },
  },
  computed: {
    // activeName(){
    //     return store.state.uploadWork.activeName
    // }
    ...mapGetters(["workListObj"]),
  },
  watch: {
    getListParams: {
      handler: function () {
        this.loading = true;
				let params = this.$dealObjectValue(this.getListParams);
        this.$store
          .dispatch("app/getWorkList", params)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.myuploadBox {
  position: relative;
  border-radius: 0.06rem;
  overflow: hidden;
  background: #ffffff;
  padding: 0.2rem 0.3rem 0;
  min-height: 9.92rem;
  width: 11rem;
  margin-bottom: 0.3rem;
}
</style>