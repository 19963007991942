<!--
 * @FilePath: \zsw_new_edition\src\views\upload\componts\MyUploadList.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-09 17:38:07
-->
<template>
  <div class="box">
    <div class="listBox" v-if="cateId == 561 && workList.data && workList.total > 0">
      <SuItem
        :item="item"
        v-for="item in workList.data"
        :key="item.id"
        @offShelf="offShelf"
      ></SuItem>
    </div>
    <div class="listBox" v-if="cateId == 1393 && workList.data && workList.total > 0">
      <MaxItem
          :item="item"
          v-for="item in workList.data"
          :key="item.id"
          @offShelf="offShelf"
      ></MaxItem>
    </div>
    <div class="listBox" v-if="cateId == 7 && workList.data && workList.total > 0">
      <MaterialItem
        :item="item"
        v-for="item in workList.data"
        :key="item.id"
        @offShelf="offShelf"
      ></MaterialItem>
    </div>
    <div class="listBox" v-if="cateId == 1394 && workList.data && workList.total > 0">
      <SchemeTextItem
          :item="item"
          v-for="item in workList.data"
          :key="item.id"
          @offShelf="offShelf"
      ></SchemeTextItem>
    </div>
    <div class="listBox" v-if="cateId == 6 && workList.data && workList.total > 0">
      <CourseItem
        :item="item"
        v-for="item in workList.data"
        :key="item.id"
        @offShelf="offShelf"
      ></CourseItem>
    </div>
    <div class="listBox" v-if="cateId == 2381 && workList.data && workList.total > 0">
      <PhotoItem
        ref="PhotoItem"
        :photoList="workList.data"
        :total="workList.total"
        @nextPage="nextPage"
        @offShelf="offShelf"
      ></PhotoItem>
    </div>
		<div class="listBox" v-if="cateId == 2205 && workList.data && workList.total > 0">
			<ChartletItem
				ref="ChartletItem"
				:chartletList="workList.data"
				:total="workList.total"
				@nextPage="nextPage"
        @offShelf="offShelf"
			></ChartletItem>
		</div>
    <div class="listBox" v-if="cateId == 1196 && workList.data && workList.total > 0">
      <PsItem
        :item="item"
        v-for="item in workList.data"
        :key="item.id"
        @offShelf="offShelf"
      ></PsItem>
    </div>
		
    <div
      class="pagination"
      v-if="workList.total > 0 && cateId != 2381 && cateId != 2205 && workList.per_page != 15"
    >
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="setPage"
        :total="workList.total"
        :page-size="workList.per_page"
      ></el-pagination>
    </div>
    <el-empty description="没有内容哦" v-if="workList.total == 0"></el-empty>
  </div>
</template>
<script>
import SuItem from "./SuItem.vue";
import MaxItem from "./MaxItem.vue";
import MaterialItem from "./MaterialItem.vue";
import SchemeTextItem from "./SchemeTextItem.vue";
import CourseItem from "./CourseItem.vue";
import PhotoItem from "./PhotoItem.vue";
import ChartletItem from "./ChartletItem.vue";
import PsItem from "./PsItem.vue";
import { offShelf } from "../../../Api";
import { getToken } from "../../../utils/auth";
export default {
  name: "MyUploadList",
  props: ["workList", "cateId"],
  components: {
    SuItem,
    MaxItem,
    SchemeTextItem,
    MaterialItem,
    CourseItem,
    PhotoItem,
		ChartletItem,
    PsItem
  },
  methods: {
    clear() {
			this.$refs.PhotoItem && this.$refs.PhotoItem.clear();
			this.$refs.ChartletItem && this.$refs.ChartletItem.clear();
    },
    setPage(val) {
      this.$emit("setPage", val);
    },
    offShelf(id) {
			console.log(id, '----23181');
			let params = {
				token: getToken(),
				id: id,
			}
			if (this.cateId == 2205 ) {
				params.fid = 2205
			}
			if (this.cateId == 2381 ) {
				params.fid = 2381
			}
			console.log(id, '----23181');
      this.$confirm("此操作将下架文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        offShelf(params).then((res) => {
          if (res.data.message == "addSuccess") {
            this.$message({
              type: "success",
              message: "下架成功",
            });
            this.$emit("initData", this.cateId);
          }
        });
      });
    },
    nextPage() {
      this.$emit("nextPage");
    },
  },
};
</script>
<style scoped>
.listBox {
  margin-top: 0.45rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.box {
  /* min-height: 7.4rem; */
  width: 100%;
}

.pagination {
  width: 100%;
  text-align: center;
}
</style>