<!--
 * @FilePath: \zsw_new_edition\src\views\upload\componts\PsItem.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-12-20 19:49:36
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-04 17:30:16
-->
<template>
  <div class="itemBox">
    <div class="itemImg">
      <router-link
        target="_blank"
        :to="{ path: '/ps/detail', query: { detailId: item.pid } }"
      >
        <img :src="item.img" alt=""
      /></router-link>
    </div>
    <div class="itemInfoBox">
      <div>
        <div class="itemInfo">
          <p class="txt-over f0404 f14 f_b">
            {{ item.title }}
          </p>
          <!-- <div class="mt10">
            <span class="c_bf f14 mr15"
              >单价:&nbsp;&nbsp;{{ item.money | splitMoney }}元</span
            >
            <span class="c_bf f14 mr15">|</span>
            <span class="c_bf f14"
              >累计收益:&nbsp;&nbsp;{{ item.profit }}元</span
            >
          </div> -->
        </div>
        <!-- <div class="df_Yc mt10" v-if="item.status == 4">
          <p class="df_Yc c_bf mr40">
            <i class="iconfont f20 mr8">&#xe628;</i>
            <span class="f12">{{ item.view }}</span>
          </p>
          <p class="df_Yc c_bf">
            <i class="iconfont f20 mr8">&#xe63a;</i>
            <span class="f12">{{ item.down }}</span>
          </p>
        </div> -->
        <div class="df_Yc mt10" v-if="item.status == 3">
          <p class="df_Yc c_bf mr40">
            <span class="f12" style="color: #040404"
              >正在审核中 请耐心等待</span
            >
          </p>
        </div>
        <div class="df_Yc mt15" v-if="item.status == 1">
          <p class="df_Yc c_bf mr40 f12 reason">未通过{{ item.reason }}</p>
        </div>
        <div class="df_Yc mt10" v-if="item.status == 5">
          <p class="df_Yc c_bf mr40">
            <span class="f12" style="color: #040404">该发布已下架</span>
          </p>
        </div>
      </div>
      <div class="itemButBox">
        <div class="f12 c_bf mt10">{{ item.addTime }}</div>
        <div
          class="itemBut2 dfc"
          v-if="item.status == 4"
          @click="offShelf(item.id)"
        >
          下架
        </div>
        <div class="itemBut1 dfc" v-if="item.status == 3">下架</div>
        <div class="itemBut dfc" v-if="item.status == 1" @click="edit(item.id)">
          编辑
        </div>
        <div class="itemBut dfc" v-if="item.status == 5" @click="edit(item.id)">
          编辑
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuItem",
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    offShelf(id) {
      this.$emit("offShelf", id);
    },
    edit(id) {
      this.$router.push({
        path: "/upload/uploadWork",
        query: { activeName: "ps", id: id },
      });
    },
  },
};
</script>

<style scoped>
.reason {
  color: #f46600;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.itemBox {
  width: 5rem;
  height: 1.5rem;
  padding: 0.15rem;
  box-shadow: 0px 0px 0.06rem rgba(102, 102, 102, 0.2);
  display: flex;
  align-items: center;
  border-radius: 0.06rem;
  margin-bottom: 00.2rem;
}

.itemImg {
  width: 1.6rem;
  height: 1.2rem;
  border-radius: 0.08rem;
  overflow: hidden;
  background: coral;
  margin-right: 00.1rem;
}

.itemImg img {
  width: 1.6rem;
  height: 1.2rem;
}

.itemBut {
  background: #ff9e58;
  width: 0.72rem;
  height: 0.35rem;
  border-radius: 0.04rem;
  overflow: hidden;
  color: #ffffff;
  font-size: 0.14rem;
  cursor: pointer;
}

.itemBut1 {
  background: #bfbfbf;
  width: 0.72rem;
  height: 0.35rem;
  border-radius: 0.04rem;
  overflow: hidden;
  font-size: 0.14rem;
  cursor: pointer;
  color: #ffffff;
}

.itemBut2 {
  background: #fff4e0;
  width: 0.72rem;
  height: 0.35rem;
  border-radius: 0.04rem;
  overflow: hidden;
  font-size: 0.14rem;
  cursor: pointer;
  color: #ff9e58;
}
.itemButBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemInfoBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  width: 2.9rem;
}
</style>