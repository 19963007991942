<template>
  <div class="box" ref="box">
    <waterfall :col="5"  :data="imgList" @loadmore="loadmore" :gutterWidth="0">
      <template>
        <div class="item" v-for="(item, index) in imgList" :key="index">
          <div class="">
            <router-link
              :to="{ path: '/chartlet/detail', query: { detailId: item.id } }"
            >
              <img v-if="item.img" :src="item.img" alt="" />
            </router-link>
						
						<div class="shade">
							<!-- 已通过 -->
							<div class="dow" @click="offShelf(item.id, index)" v-if="item.status == 3">下架</div>
							<!-- 未通过、已下架 -->
							<div class="dow" @click="edit(item.pid, index)" v-if="item.status == 2 || item.status == 1">编辑</div>
							<!-- 待审核 -->
							<p class="wait"  v-if="item.status == 4">待审核</p>
							<!-- 未通过 -->
							<p class="noPass" v-if="item.status == 2">未通过{{ item.reason }}</p>
						</div>
						
          </div>
					<div class="item-title txt-over">{{item.title}}</div>
        </div>
      </template>
    </waterfall>
    <p
      class="t_c mt20 loadImg"
      :class="{ load: isLoading }"
      v-if="chartletList.length > 0"
    >
      <img src="@/assets/image/loading.gif" alt="" />
    </p>
  </div>
</template>

<script>
import { delPhoto } from "../../../Api";
import { getToken } from "../../../utils/auth";
export default {
  name: "ChartletItem",
  props: {
    chartletList: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
    },
  },
  components: {},
  data() {
    return {
      imgList: [],
      isLoading: false,
    };
  },
  methods: {
    offShelf(id) {
      this.$emit("offShelf", id);
    },
    edit(id) {
      this.$router.push({
        path: "/upload/uploadWork",
        query: { activeName: "chartlet", id: id },
      });
    },
    deletePhoto(id, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delPhoto({ id: id, token: getToken() }).then((res) => {
          if (res.data.message == "delSuccess") {
            this.imgList.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功",
            });
          }
        });
      });
    },
    clear() {
      this.imgList = [];
    },
    loadmore() {
      this.$emit("nextPage");
    },
    pushData() {
      // if (!this.chartletList[0].addTime) {
         this.imgList = this.imgList.concat(this.chartletList)
      // }
      this.isLoading = false;
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const offsetTop = this.$refs.box.offsetHeight;
      if (scrollTop + windowHeight >= offsetTop && !this.isLoading) {
        //到了这个就可以进行业务逻辑加载后台数据了
        this.isLoading = true;
        this.loadmore();
      }
    },
  },
  mounted() {
    this.pushData();
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    chartletList() {
      if (this.imgList.length < this.total) this.pushData();
    },
  },
};
</script>
<style scoped>
.loadImg {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.loadImg.load {
  visibility: visible;
  opacity: 1;
}
.vue-waterfall {
  width: 101%;
  padding-top: 0.1rem;
}
.box {
  /* padding-bottom: 0.5rem; */
}

.item:hover {
  transform: translateY(-6px);
  box-shadow: none;
}
.item-title {
	height: 0.18rem;
	line-height: 0.18rem;
	font-size: 0.12rem;
	font-weight: 500;
	color: rgba(102, 102, 102, 1);
	text-align: left;
}

.item {
  /* width: 3.2rem; */
  overflow: hidden;
  border-radius: 0.04rem;
  margin-bottom: 0.2rem;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  display: block;
  margin: 0 0.2rem 0.2rem 0;
}

.item img {
  width: 100%;
	display: block;
  border-radius: 0.04rem;
}
.item:hover .shade {
  visibility: visible;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0.18rem;
	left: 0;
  border-radius: 0.04rem;
	background: rgba(0, 0, 0, 0.3);
}

.shade {
	visibility: hidden;
}
.dow {
  background: #ffffff;
  opacity: 0.79;
  position: absolute;
  top: 0.08rem;
  right: 0.08rem;
  font-size: 0.14rem;
  padding: 0.07rem 0.15rem;
  border-radius: 0.04rem;
  transition: 0.5s;
  font-family: PingFang SC;
  color: #333333;
}


.wait {
  width: 100%;
	height: 100%;
  position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
  font-size: 0.14rem;
  font-family: PingFang SC;
	font-weight: 500;
	color: rgba(255, 255, 255, 1);
  text-align: center;
}
.noPass {
	width: 100%;
	position: absolute;
	bottom: 0.16rem;
	font-size: 0.14rem;
	font-family: PingFang SC;
	font-weight: 500;
	color: rgba(255, 255, 255, 1);
	text-align: center;
	
}

.collected {
  color: #f46600;
}
</style>