<template>
  <div class="myuploadStatistics">
    <div class="earnings">
      <p class="earningsNumber">{{data.income | changeW}}</p>
      <p class="yuan">收益 (元)</p>
    </div>
    <div class="lineY"></div>
    <div class="myuploadStatisticsList">
      <div class="statisticsItem1">
        <div class="statisticsItemTitle">
          <p class="f14">上传总数</p>
          <p class="f18 c_b">{{ data.uploadNum | changeW }}</p>
        </div>
        <p class="f12">Total uploads</p>
      </div>
      <div class="statisticsItem2">
        <div class="statisticsItemTitle">
          <p class="f14">浏览次数</p>
          <p class="f18 c_b">{{ data.viewNum | changeW  }}</p>
        </div>
        <p class="f12">Number of views</p>
      </div>
      <div class="statisticsItem3">
        <div class="statisticsItemTitle">
          <p class="f14">被下载次数</p>
          <p class="f18 c_b">{{ data.downloadNum | changeW  }}</p>
        </div>
        <p class="f12">Download</p>
      </div>
    </div>
  </div>
</template>
<script>
import { workStatistic } from "../../../Api";
import { getToken } from '../../../utils/auth';
export default {
  name: "MyUploadStatistics",
  data() {
    return {
      data: {},
    };
  },
  created() {
    workStatistic({ token: getToken() }).then((res) => {
      this.data = res.data;
    });
  },
};
</script>
<style scoped>
.statisticsItemTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statisticsItem1 {
  width: 2rem;
  height: 0.9rem;
  background: url("../../../assets/image/bg/myUpload1-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 0.1rem 0.2rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.statisticsItem2 {
  width: 2rem;
  height: 0.9rem;
  background: url("../../../assets/image/bg/myUpload2-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 0.1rem 0.2rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.statisticsItem3 {
  width: 2rem;
  height: 0.9rem;
  background: url("../../../assets/image/bg/myUpload3-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 0.1rem 0.2rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.myuploadStatisticsList {
  flex: 1;
  padding: 0 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lineY {
  background: #d9d9d9;
  height: 0.4rem;
  width: 0.02rem;
  margin-right: 00.2rem;
}
.yuan {
  margin-top: 00.14rem;
  color: #cccccc;
  font-size: 0.14rem;
}
.earnings {
  text-align: center;
  width: 2.3rem;
}
.earningsNumber {
  color: #040404;
  font-size: 0.26rem;
  font-weight: bold;
}
.myuploadStatistics {
  display: flex;
  align-items: center;
}
</style>